import Order from "./Order";
import { connect } from "react-redux";
import { getOrderRequest, updateOrder } from "../../store/order/actions";

const mapStateToProps = state => ({
  order: state.order
});

const mapDispatchToProps = dispatch => ({
  getOrderRequest: async () => dispatch(await getOrderRequest()),
  updateOrder: async data => dispatch(await updateOrder(data))
});

const OrderContainer = connect(mapStateToProps, mapDispatchToProps)(Order);
export default OrderContainer;
