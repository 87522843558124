import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "../common/Input";
import Textarea from "../common/Textarea";
import InputRadio from "../common/InputRadio";
import Button from "../common/Button";
import FormInfoPopup from "../common/FormInfoPopup";
import RodoInfo from "../common/RodoInfo";
import useForm from "./useForm";
import { getErrorMessageByFieldName } from "../../utils/common";

const OrderForm = ({ lang, t }) => {
  const captchaRef = useRef();

  const [infoPopup, setInfoPopup] = useState("");
  const [values, updateValue, submitHandler, verifyCallback, errors] = useForm(
    {
      legalForm: "person",
      firstName: "",
      lastName: "",
      companyName: "",
      taxId: "",
      zipCode: "",
      city: "",
      street: "",
      houseNumber: "",
      email: "",
      phone: "",
      message: "",
      captcha: null
    },
    captchaRef,
    setInfoPopup,
    t
  );

  return (
    <form className="Order__form" onSubmit={submitHandler}>
      <div className="form-column">
        <InputRadio labelText="Osoba prywatna" name="legalForm" value="person" onChange={updateValue} checked={values.legalForm === "person"} />
        <InputRadio labelText="Firma" name="legalForm" value="company" onChange={updateValue} checked={values.legalForm === "company"} />
      </div>
      <div className="form-column">
        <Input
          name="firstName"
          type="text"
          value={values.firstName}
          onChange={updateValue}
          labelText={t.name.placeholder}
          maxLength={255}
          errorMessage={getErrorMessageByFieldName(errors, "firstName")}
        />
        <Input
          name="lastName"
          type="text"
          value={values.lastName}
          onChange={updateValue}
          labelText={t.surname.placeholder}
          maxLength={255}
          errorMessage={getErrorMessageByFieldName(errors, "lastName")}
        />
      </div>

      {values.legalForm === "company" && (
        <>
          <Input
            name="companyName"
            type="text"
            value={values.companyName}
            onChange={updateValue}
            labelText={t.company.placeholder}
            maxLength={255}
            errorMessage={getErrorMessageByFieldName(errors, "companyName")}
          />
          <Input
            name="taxId"
            type="text"
            value={values.taxId}
            onChange={updateValue}
            labelText={t.taxId.placeholder}
            maxLength={11}
            errorMessage={getErrorMessageByFieldName(errors, "taxId")}
          />
        </>
      )}

      <div className="form-column">
        <Input
          name="zipCode"
          type="text"
          value={values.zipCode}
          onChange={updateValue}
          labelText={t.zipCode.placeholder}
          maxLength={6}
          errorMessage={getErrorMessageByFieldName(errors, "zipCode")}
        />
        <Input
          name="city"
          type="text"
          value={values.city}
          onChange={updateValue}
          labelText={t.city.placeholder}
          maxLength={255}
          errorMessage={getErrorMessageByFieldName(errors, "city")}
        />
      </div>
      <div className="form-column">
        <Input
          name="street"
          type="text"
          value={values.street}
          onChange={updateValue}
          labelText={t.street.placeholder}
          maxLength={255}
          errorMessage={getErrorMessageByFieldName(errors, "street")}
        />
        <Input
          name="houseNumber"
          type="text"
          value={values.houseNumber}
          onChange={updateValue}
          labelText={t.houseNumber.placeholder}
          maxLength={6}
          errorMessage={getErrorMessageByFieldName(errors, "houseNumber")}
        />
      </div>
      <div className="form-column">
        <Input
          name="email"
          type="email"
          value={values.email}
          onChange={updateValue}
          labelText={t.email.placeholder}
          maxLength={255}
          errorMessage={getErrorMessageByFieldName(errors, "email")}
        />
        <Input
          name="phone"
          type="text"
          value={values.phone}
          onChange={updateValue}
          labelText={t.phone.placeholder}
          maxLength={16}
          errorMessage={getErrorMessageByFieldName(errors, "phone")}
        />
      </div>

      <Textarea
        name="message"
        value={values.message}
        onChange={updateValue}
        rows={10}
        labelText={t.message.placeholder}
        maxLength={500}
        errorMessage={getErrorMessageByFieldName(errors, "message")}
      />

      <div className="ReCAPTCHA">
        <ReCAPTCHA ref={captchaRef} sitekey={process.env.RECAPTCHA_PUBLIC_KEY} onChange={verifyCallback} hl={lang} />
        {getErrorMessageByFieldName(errors, "captcha") && <p className="error">{getErrorMessageByFieldName(errors, "captcha")}</p>}
      </div>

      <Button>{t.submit}</Button>

      <RodoInfo t={t.rodoInfo} />

      {infoPopup && <FormInfoPopup info={infoPopup} close={() => setInfoPopup("")} />}
    </form>
  );
};

OrderForm.propTypes = {
  lang: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired
};

export default OrderForm;
