import React from "react";
import PropTypes from "prop-types";

const InputRadio = ({ labelText, ...attributes }) => (
  <div className="InputRadio">
    <label className="InputRadio__label">
      {labelText}
      <input type="radio" {...attributes} />
      <span className="checkmark"></span>
    </label>
  </div>
);

InputRadio.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  labelText: PropTypes.string.isRequired
};

export default InputRadio;
