import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Layout from "../../layouts/Layout";
import Seo from "../seo";
import Intro from "../common/Intro";
import Breadcrumb from "../common/Breadcrumb";
import OrderForm from "./OrderForm";
import { getShareImage } from "../../utils/getShareImage";

import imgClose from "../../images/common/close-dark.svg";

const Order = props => {
  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;
  const { order, getOrderRequest, updateOrder } = props;

  const [phone, setPhone] = useState("");

  useEffect(() => {
    setPhone(process.env.PHONE);
    getOrderRequest();
  }, [getOrderRequest]);

  const removeProduct = (productId, index) => {
    const data = [];
    if (order.data[index].id === productId) {
      order.data.forEach((elem, i) => i !== index && data.push(elem));
    }
    updateOrder(data);
  };

  const updateProductQuantity = ({ productId, productIndex, quantity, add }) => {
    const data = [];
    let newQuantity = quantity;

    if (add) newQuantity++;
    else newQuantity--;
    if (newQuantity < 1) newQuantity = 1;

    order.data.forEach((elem, i) => {
      if (elem.id === productId && i === productIndex) data.push({ id: productId, quantity: newQuantity, modifier: elem.modifier, divide: elem.divide });
      else data.push(elem);
    });
    updateOrder(data);
  };

  const updateProductDivideQuantity = ({ productId, productIndex, divide, add }) => {
    const data = [];
    const newDivide = [];
    let newQuantity = divide.quantity;

    if (add) newQuantity++;
    else newQuantity--;
    if (newQuantity < 1) newQuantity = 1;

    order.data.forEach((elem, i) => {
      if (elem.id === productId && i === productIndex) {
        elem.divide.forEach(element => {
          if (element.value === divide.value) newDivide.push({ value: element.value, quantity: newQuantity });
          else newDivide.push(element);
        });
        data.push({ id: productId, quantity: elem.quantity, modifier: elem.modifier, divide: newDivide });
      } else data.push(elem);
    });
    updateOrder(data);
  };

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname} withoutOrderLeaf>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} />

      <div className="Order">
        {!order.data || !order.data.length ? (
          <div className="container Order__empty">
            <p>{t.empty}</p>
          </div>
        ) : (
          <div className="container">
            <p className="small-title">{t.info}</p>
            <div className="Order__data">
              <a href={`mailto:${t.email}`}>{t.email}</a>
              {lang === "pl" && !!phone && <a href={`tel:${phone}`}>{phone}</a>}
            </div>

            <div className="Order__container">
              <OrderForm lang={lang} t={t.form} />

              <div className="Order__summary">
                <p className="small-title small-title--withMargin">{t.summary.title}:</p>

                <table className="Order__table">
                  <thead>
                    <tr>
                      <th>{t.summary.product}:</th>
                      <th>{t.summary.quantity}:</th>
                      <th>{t.summary.price}:</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.data.map((elem, i) => (
                      <tr key={i}>
                        <td>{elem.id}</td>
                        <td>
                          {!elem.divide.length ? (
                            <div className="Order__table__quantity">
                              <button
                                className="Order__table__quantity__btn"
                                onClick={() => updateProductQuantity({ productId: elem.id, productIndex: i, quantity: elem.quantity, add: false })}
                              >
                                -
                              </button>
                              <p className="Order__table__quantity__number">{elem.quantity}</p>
                              <button
                                className="Order__table__quantity__btn"
                                onClick={() => updateProductQuantity({ productId: elem.id, productIndex: i, quantity: elem.quantity, add: true })}
                              >
                                +
                              </button>
                            </div>
                          ) : (
                            elem.divide.map((element, index) => (
                              <div key={index} className="Order__table__quantity__divide">
                                <p className="Order__table__quantity__divide__value">{element.value}:</p>
                                <div className="Order__table__quantity">
                                  <button
                                    className="Order__table__quantity__btn"
                                    onClick={() => updateProductDivideQuantity({ productId: elem.id, productIndex: i, divide: element, add: false })}
                                  >
                                    -
                                  </button>
                                  <p className="Order__table__quantity__number">{element.quantity}</p>
                                  <button
                                    className="Order__table__quantity__btn"
                                    onClick={() => updateProductDivideQuantity({ productId: elem.id, productIndex: i, divide: element, add: true })}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            ))
                          )}
                        </td>
                        <td>&nbsp;</td>
                        <td>
                          <button className="Order__summary__close" onClick={() => removeProduct(elem.id, i)}>
                            <img src={imgClose} alt="remove" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="Order__table__summary">
                  <p>{t.summary.total}:</p>
                  <p className="Order__table__summary__price">PLN</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

Order.propTypes = {
  pageContext: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  getOrderRequest: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired
};

export default Order;
