import { useState } from "react";
import FormService from "../../services/FormService";
import { isName, isEmail } from "../../utils/validation";
import { getCookie, removeCookie } from "../../utils/cookie";

const useForm = (initial, captchaRef, setInfoPopup, t) => {
  const [values, setValues] = useState(initial);
  const [errors, setErrors] = useState([]);

  const updateValue = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const resetCaptcha = () => {
    setValues({ ...values, captcha: null });
    captchaRef.current.reset();
  };

  const verifyCallback = response => {
    setValues({ ...values, captcha: response });
  };

  const validate = values => {
    const errorsList = [];

    if (!values.firstName) errorsList.push({ fieldName: "firstName", errorMessage: t.name.errors[0] });
    else if (!isName(values.firstName)) errorsList.push({ fieldName: "firstName", errorMessage: t.name.errors[1] });
    if (!values.lastName) errorsList.push({ fieldName: "lastName", errorMessage: t.surname.errors[0] });
    else if (!isName(values.lastName)) errorsList.push({ fieldName: "lastName", errorMessage: t.surname.errors[1] });
    if (values.legalForm === "company") {
      if (!values.companyName) errorsList.push({ fieldName: "companyName", errorMessage: t.company.errors[0] });
      if (!values.taxId) errorsList.push({ fieldName: "taxId", errorMessage: t.taxId.errors[0] });
    }
    if (!values.zipCode) errorsList.push({ fieldName: "zipCode", errorMessage: t.zipCode.errors[0] });
    if (!values.city) errorsList.push({ fieldName: "city", errorMessage: t.city.errors[0] });
    if (!values.street) errorsList.push({ fieldName: "street", errorMessage: t.street.errors[0] });
    if (!values.houseNumber) errorsList.push({ fieldName: "houseNumber", errorMessage: t.houseNumber.errors[0] });
    if (!values.email) errorsList.push({ fieldName: "email", errorMessage: t.email.errors[0] });
    else if (!isEmail(values.email)) errorsList.push({ fieldName: "email", errorMessage: t.email.errors[1] });
    if (!values.phone) errorsList.push({ fieldName: "phone", errorMessage: t.phone.errors[0] });
    if (!values.message) errorsList.push({ fieldName: "message", errorMessage: t.message.errors[0] });
    if (!captchaRef.current.getValue()) errorsList.push({ fieldName: "captcha", errorMessage: t.captcha.errors[0] });

    return errorsList;
  };

  const submitHandler = async e => {
    e.preventDefault();

    if (validate(values).length > 0) {
      resetCaptcha();
      return setErrors(validate(values));
    }
    if (errors.length > 0) setErrors([]);

    const currentData = {
      ...values,
      services: [getCookie("or")]
    };

    try {
      const response = await FormService.sendOrderForm({ currentData });
      if (!!response.success) setInfoPopup(t.success);
      else throw response;

      resetCaptcha();
      setValues(initial);
      removeCookie("or");
    } catch (err) {
      setInfoPopup(t.error);
    }
  };

  return [values, updateValue, submitHandler, verifyCallback, errors];
};

export default useForm;
